/**
 * Path definitions shared across components
 */

// Paths that use 2024 styling
export const newStylePaths = [
  '/2024/',
  '/what-is-stile/stile-pl/',
  '/what-is-stile/professional-learning/',
  '/states/oregon/',
  '/states/oregon-alignment/',
  '/states/north-carolina/',
  '/states/north-carolina-alignment/',
  `/who-we-are/our-principles/`,
  `/what-is-stile/whats-included/`,
  `/what-is-stile/responsive-support/`,
  `/why-choose-stile/responsive-support/`,
  `/what-is-stile/technology-for-teaching/`,
  `/why-choose-stile/boost-reading-writing-and-math/`,
  `/why-choose-stile/literacy-and-numeracy/`,
  `/why-choose-stile/high-quality-instructional-materials/`,
  `/what-is-stile/stile-x/`,
  `/simulations/`,
  `/resources/victorian-curriculum/`,
];

// Helper function to determine if a path uses 2024 styling
export function matchesNewStylePath(path: string): boolean {
  // Avoid adding the homepages to the list above, as it'll match everything
  if (path === '/') {
    return true;
  }

  return newStylePaths.some((stylePath) => path.startsWith(stylePath));
}
