import React from 'react';

import styled from 'styled-components';

const HeartsContainer = styled.div`
  display: inline-block;
  position: absolute;
  height: 0;
  display: flex;
`;

const HeartsContent = styled.div`
  position: relative;
  display: flex;
  width: 40px;
  gap: 10px;
`;

const Particle = styled.span`
  opacity: 0;
  position: absolute;
  background-color: var(--stile-green-1);
  animation: hearts 3s ease-in infinite;
  width: 10px;
  height: 10px;

  &:before,
  &:after {
    position: absolute;
    content: '';
    border-radius: 100px;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--stile-green-1);
  }

  &:before {
    transform: translateX(-50%);
  }

  &:after {
    transform: translateY(-50%);
  }

  @keyframes hearts {
    0% {
      opacity: 0;
      transform: translate(0, 0%) rotate(45deg);
    }
    20% {
      opacity: 0.8;
      transform: translate(0, -20%) rotate(45deg);
    }
    100% {
      opacity: 0;
      transform: translate(0, -1000%) rotate(45deg);
    }
  }
`;

const particleStyles = [
  { left: '0%', animationDelay: '0.8s' },
  { left: '15px', animationDelay: '2.5s' },
  { left: '30px', animationDelay: '3.5s' },
];

const Hearts: React.FC = () => {
  return (
    <HeartsContainer>
      <HeartsContent>
        {particleStyles.map((style, index) => (
          <Particle key={index} style={{ ...style, zIndex: 9999 }} />
        ))}
      </HeartsContent>
    </HeartsContainer>
  );
};

export default Hearts;
