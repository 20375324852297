import React, { ReactNode } from 'react';

import styled from 'styled-components';

import VideoIcon from 'stile-shared/src/components/2024/common/VideoIcon';
import { Grid } from 'stile-shared/src/components/2024/layout/Grid';

const StyledHearFromSection = styled.div`
  background-color: var(--stile-grey-8);
  color: var(--white);

  .content {
    padding: 150px 0;

    @media (max-width: 768px) {
      padding: 60px 0;
    }

    .grid-layout {
      .left,
      .right {
        position: relative;

        h2 {
          padding: 0;
          margin: 0;
          margin-bottom: 32px;
          @media (max-width: 768px) {
            margin-bottom: 20px;
          }
        }

        p {
          padding: 0;
          margin: 0;
          margin-bottom: 32px;

          @media (max-width: 768px) {
            margin-top: 20px;
            margin-bottom: 0;
          }
        }
      }
      .left {
        grid-column: 1 / span 5;
        @media (max-width: 768px) {
          grid-column: 1 / span 12;
          padding-bottom: 30px;
        }
      }
      .right {
        .primaryPlayButton path {
          fill: var(--stile-green-1);
        }
        grid-column: 7 / span 6;
        @media (max-width: 768px) {
          grid-column: 1 / span 12;
        }
      }
    }
  }
`;

type HearFromSectionProps = {
  heading: string;
  subheading: string;
  image: ReactNode;
  youtubeUrl: string;
} & React.HTMLAttributes<HTMLDivElement>;

export default function HearFromSection({
  heading,
  subheading,
  image,
  youtubeUrl,
}: HearFromSectionProps) {
  return (
    <StyledHearFromSection>
      <div className="content">
        <Grid>
          <div className="left">
            <h2>{heading}</h2>
            <div className="--text">
              <p>{subheading}</p>
            </div>
          </div>
          <div className="right">
            <VideoIcon image={image} youtubeUrl={youtubeUrl} />
          </div>
        </Grid>
      </div>
    </StyledHearFromSection>
  );
}
