import * as React from 'react';

import { SkipNavContent } from '@reach/skip-nav';
import styled from 'styled-components';

import {
  CONTACT,
  FOOTER_LINK_SECTIONS,
  HEADER_LINK_SECTIONS,
  OTHER_SITE_LINKS,
  SITE_LOCALE,
  STILE_APP_URL,
} from 'app-constants';

import { Footer } from 'stile-shared/src/components/2020/common/Footer';
import { Header } from 'stile-shared/src/components/2020/common/Header';
import { Main } from 'stile-shared/src/components/2020/common/Primitives';
import { SiteSwitchModal } from 'stile-shared/src/components/2020/common/SiteSwitchModal';

import ESBadge from '../../../../stile-shared/assets/images/edsurge_pi_badge.png';
import SPPBadge from '../../../../stile-shared/assets/images/privacy/student_privacy_pledge.png';

export type PageLayoutProps = {
  children: React.ReactNode;
};

export function PageLayout({ children }: PageLayoutProps) {
  const [trialLabel, setTrialLabel] = React.useState('Connect with a Curriculum Specialist');

  React.useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth <= 950) {
        setTrialLabel('Enquire Now');
      } else {
        setTrialLabel('Connect with a Curriculum Specialist');
      }
    };

    checkWindowSize();

    window.addEventListener('resize', checkWindowSize);

    return () => window.removeEventListener('resize', checkWindowSize);
  }, []);
  return (
    <>
      <PageContainer>
        <Header
          linkSections={HEADER_LINK_SECTIONS}
          primaryButton={{
            text: trialLabel,
            gtagEvent: 'enquire_now_us',
          }}
          secondaryButton={{ text: 'Log in', url: STILE_APP_URL }}
        />

        <SiteSwitchModal locale={SITE_LOCALE} />

        <Main>
          <SkipNavContent />
          {children}
        </Main>

        <Footer
          locale={SITE_LOCALE}
          phone={CONTACT.us.phone}
          linkSections={FOOTER_LINK_SECTIONS}
          otherLinks={OTHER_SITE_LINKS}
          badges={[
            {
              text: 'Student Data Privacy Pledge',
              url: 'https://studentprivacypledge.org/',
              src: SPPBadge,
              width: 110,
            },
            {
              text: 'Find us on the EdSurge Product Index',
              url: 'https://index.edsurge.com/product/ultid/PF4C-3F2C-7F4F-4D53-8E/',
              src: ESBadge,
              width: 80,
            },
          ]}
          statement={CONTACT.us.address.descriptive}
        />
      </PageContainer>
    </>
  );
}

export const PageContainer = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  width: 100%;
  height: 100%;
`;
