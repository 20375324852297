// src/pages/whats-included.tsx
import React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import cardpic01 from 'stile-shared/static/wit-box-images/01_instructional_materials.png';
import cardpic02 from 'stile-shared/static/wit-box-images/02_lab_kits.png';
import cardpic03 from 'stile-shared/static/wit-box-images/03_revision_workbooks.png';
import cardpic04 from 'stile-shared/static/wit-box-images/04_planning_guides.png';
import cardpic05 from 'stile-shared/static/wit-box-images/05_professional_learning.png';
import cardpic06 from 'stile-shared/static/wit-box-images/06_powerful_platform.png';
import cardpic07 from 'stile-shared/static/wit-box-images/07_responsive_support.png';
import styled from 'styled-components';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import { IconSet } from 'stile-shared/src/components/2024/common/IconSet/IconSet';
import WITBFeatureSection from 'stile-shared/src/components/2024/common/Included/WITBFeatureSection/WITBFeatureSection';
import WITBGridContainer from 'stile-shared/src/components/2024/common/Included/WITBGridContainer/WITBGridContainer';
import WITBGridItem from 'stile-shared/src/components/2024/common/Included/WITBGridItem/WITBGridItem';
import WITBGridRow from 'stile-shared/src/components/2024/common/Included/WITBGridRow/WITBGridRow';
import WITBStatsSection from 'stile-shared/src/components/2024/common/Included/WITBStatsSection/WITBStatsSection';
import WITBTile from 'stile-shared/src/components/2024/common/Included/WITBTile/WITBTile';
import WITBTilesContainer from 'stile-shared/src/components/2024/common/Included/WITBTilesContainer/WITBTilesContainer';
import WITBTitleMenu from 'stile-shared/src/components/2024/common/Included/WITBTitleMenu/WITBTitleMenu';
import WITBTopSection from 'stile-shared/src/components/2024/common/Included/WITBTopSection/WITBTopSection';
import ScrollIndicatorContainer from 'stile-shared/src/components/2024/common/ScrollIndicatorContainer/ScrollIndicatorContainer';
import BottomCTA from 'stile-shared/src/components/2024/layout/BottomCTA';

import { PageLayout } from 'templates/2024/PageLayout';

export { Head } from 'templates/2024/Head';

// Data Arrays remain the same
const tilesData = [
  {
    title: 'Instructional materials',
    cardDetail: ['Beautiful, interactive science lessons'],
    headerImage: <img src={cardpic01} alt="Instructional materials" loading="lazy" />,
    linkId: 'instructional-materials',
  },
  {
    title: 'Lab kits',
    cardDetail: ['Kits for hands-on learning'],
    headerImage: <img src={cardpic02} alt="Lab kits" loading="lazy" />,
    linkId: 'lab-kits',
  },
  {
    title: 'Revision workbooks',
    cardDetail: ['Note-taking and study skills'],
    headerImage: <img src={cardpic03} alt="Revision workbooks" loading="lazy" />,
    linkId: 'revision-workbooks',
  },
  {
    title: 'Planning guides',
    cardDetail: ['Helping teachers prep for lessons'],
    headerImage: <img src={cardpic04} alt="Planning guides" loading="lazy" />,
    linkId: 'planning-guides',
  },
  {
    title: 'Professional learning',
    cardDetail: ['The key to evidence-based science teaching'],
    headerImage: <img src={cardpic05} alt="Professional learning" loading="lazy" />,
    linkId: 'professional-learning',
  },
  {
    title: 'Powerful platform',
    cardDetail: ['Customisable, interactive & fun'],
    headerImage: <img src={cardpic06} alt="Powerful platform" loading="lazy" />,
    linkId: 'powerful-platform',
  },
  {
    title: 'Responsive support',
    cardDetail: ['Support from Curriculum specialists'],
    headerImage: <img src={cardpic07} alt="Responsive support" loading="lazy" />,
    linkId: 'responsive-support',
  },
];

const statsData = [
  { value: '62', label: 'topics' },
  { value: '1,144', label: 'lessons' },
  { value: '73', label: 'labs' },
  { value: '215', label: 'assessments' },
  { value: '1,317', label: 'videos' },
  { value: '10,918', label: 'questions' },
  { value: '58', label: 'career profiles' },
  { value: '52', label: 'simulations' },
];

const WhatsIncludedPageLayout = styled<typeof PageLayout>(PageLayout)`
  .main-content {
    padding-bottom: 120px;

    @media (max-width: 768px) {
      padding-bottom: calc(60px - 28px);
    }
  }
`;

function WhatsIncludedPage(props: PageProps) {
  return (
    <WhatsIncludedPageLayout {...props}>
      <div className="content">
        <WITBTitleMenu>
          <WITBTopSection
            title="What's included?"
            description="Stile is a complete, comprehensive core curriculum for middle school. It’s battle tested and highly refined, having been used (and loved!) by thousands of teachers for over 10 years."
          />
          <WITBTilesContainer>
            {tilesData.map((tile, index) => (
              <WITBTile
                key={index}
                title={tile.title}
                cardDetail={tile.cardDetail}
                headerImage={tile.headerImage}
                linkId={tile.linkId}
              />
            ))}
          </WITBTilesContainer>
        </WITBTitleMenu>
      </div>

      <div className="content">
        {/* 01 - instructional materials */}
        <WITBFeatureSection id="instructional-materials" title="Instructional materials">
          <ScrollIndicatorContainer>
            <WITBGridRow>
              <WITBGridItem
                iconImage={
                  <img
                    src={IconSet['documentIcon0101']}
                    alt="Classroom lessons icon - A document"
                  />
                }
                title="Classroom lessons"
                detail="Relevant and engaging lessons set in the context of real-world discoveries and events, helping spark conversation and debate."
              />
              <WITBGridItem
                iconImage={
                  <img
                    src={IconSet['natui2Icon0102']}
                    alt="Interactive activities icon - A finger pressing a display"
                  />
                }
                title="Interactive activities"
                detail="Open-ended investigations, practical activities, and engineering challenges—risk assessments included!"
              />
              <WITBGridItem
                iconImage={
                  <img
                    src={IconSet['newsIcon0103']}
                    alt="Breaking science news icon - A news article"
                  />
                }
                title="Breaking science news"
                detail="Real-life science stories from the year so far, delivered straight to your classroom to keep lessons super fresh."
              />
            </WITBGridRow>
            <WITBGridRow>
              <WITBGridItem
                iconImage={
                  <img
                    src={IconSet['testpassIcon0104']}
                    alt="Assessments & check-ins icon - A checklist"
                  />
                }
                title="Assessments & check-ins"
                detail="Measure student progress, inform your reporting, and easily reflect on how your teaching has been so far."
              />
              <WITBGridItem
                iconImage={
                  <img
                    src={IconSet['videoIcon0105']}
                    alt="Curated and created videos icon - A play button"
                  />
                }
                title="Curated and created videos"
                detail="Curated and bespoke videos that make complex concepts click and keep your students engaged with the material."
              />
              <WITBGridItem
                iconImage={
                  <img src={IconSet['gameIcon0106']} alt="Simulations icon - A game controller" />
                }
                title="Simulations"
                detail="Interactive simulations that allow students to explore modern concepts in unique, captivating ways."
              />
            </WITBGridRow>
            <WITBGridRow>
              <WITBGridItem
                iconImage={
                  <img
                    src={IconSet['askQIcon0107']}
                    alt="Questions & model answers icon - A speech bubble with a question mark"
                  />
                }
                title="Questions & model answers"
                detail="Alongside our questions, model answers are embedded into Stile to help you mark student responses, and to help students understand the content."
              />
              <WITBGridItem
                iconImage={
                  <img
                    src={IconSet['emergIcon0108']}
                    alt="Escape Room challenges icon - A person fleeing to an exit"
                  />
                }
                title="Escape Room challenges"
                detail="Escape rooms are a creative and engaging way to challenge your students with puzzles, trivia facts, and scientific knowledge."
              />
              <WITBGridItem
                iconImage={
                  <img
                    src={IconSet['maleuserIcon0109']}
                    alt="Relatable career profiles icon - A profile icon"
                  />
                }
                title="Relatable career profiles"
                detail="Every Stile unit has a career profile of a young professional who guides students through the lessons."
              />
            </WITBGridRow>
          </ScrollIndicatorContainer>
        </WITBFeatureSection>
      </div>

      {/* Stats Section */}
      <WITBStatsSection title="Your core curriculum, ready to go" stats={statsData} />

      <div className="content">
        {/* 02 - lab kits information */}
        <WITBFeatureSection id="lab-kits" title="Lab Kits" className="first-section">
          <WITBGridContainer>
            <WITBGridRow>
              <WITBGridItem
                iconImage={<img src={IconSet['testtubeIcon0201']} alt="" />}
                title="Glassware"
                detail="We provide the beakers, conical flasks and test tubes that you need, safely housed in custom-cut foam casing."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['temperIcon0202']} alt="" />}
                title="General equipment"
                detail="From stopwatches to magnets to thermometers. Our general equipmet is robust and ready to be used repeatedly for years on end."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['batlevelIcon0203']} alt="" />}
                title="Consumables"
                detail="Some labs call for one-off consumables such as liquids, chemicals and batteries. Our lab kids come fully stocked, and we provide refills for stock you use as you go."
              />
            </WITBGridRow>
          </WITBGridContainer>
        </WITBFeatureSection>
      </div>
      <div className="content">
        {/* 03 - revision workbooks */}
        <WITBFeatureSection
          id="revision-workbooks"
          title="Revision workbooks"
          learnMoreHref="/what-is-stile/stile-x/"
        >
          <WITBGridContainer>
            <WITBGridRow>
              <WITBGridItem
                iconImage={<img src={IconSet['inscriptIcon0301']} alt="" />}
                title="Structured note-taking"
                detail="Rigorous scaffolding helps students organise handwritten notes, making it easier to lock in key concepts and master their knowledge."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['cardsIcon0302']} alt="" />}
                title="Flashcards and glossary"
                detail="Students can quickly look up definitions and create personalised flashcards to reinforce learning, recall (and confidence!)."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['testpassIcon0303']} alt="" />}
                title="Practice tests"
                detail="New revision questions and fully-worked model answers empower students to study smarter, test their knowledge, and stay on top of their learning goals."
              />
            </WITBGridRow>
          </WITBGridContainer>
        </WITBFeatureSection>
      </div>
      <div className="content">
        {/* 04 - planning guides */}
        <WITBFeatureSection id="planning-guides" title="Planning guides">
          <ScrollIndicatorContainer>
            <WITBGridRow>
              <WITBGridItem
                iconImage={<img src={IconSet['spiralIcon0401']} alt="" />}
                title="Teaching & lab guide"
                detail="Step-by-step guides that make lesson delivery and lab activities simple, smooth, and effective for teachers, saving prep time."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['timelineIcon0402']} alt="" />}
                title="Scope & sequence"
                detail="A flexible curriculum roadmap designed to cover key learning outcomes, while allowing teachers to customise it to suit their teaching style and students' needs."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['highlightIcon0403']} alt="" />}
                title="Curriculum coverage"
                detail="Curriculum-aligned documents give educators a head start on planning and ensure key learning outcomes are met."
              />
            </WITBGridRow>
            <WITBGridRow>
              <WITBGridItem
                iconImage={<img src={IconSet['lightonIcon0404']} alt="" />}
                title="In-line teacher guidance"
                detail="Real-time, evidence-based teaching strategies are woven into each lesson, providing instant, practical support."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['checklistIcon0405']} alt="" />}
                title="Lesson by lesson planning"
                detail="Pre-designed lesson plans with timing suggestions make daily prep effortless, creating more space for teachers to do what they do best—support their students."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['errorIcon0406']} alt="" />}
                title="Risk assessment"
                detail="Ready-to-use templates provide easy-to-follow safety checklists, ensuring practical lessons are safe and ready to roll."
              />
            </WITBGridRow>
            <WITBGridRow>
              <WITBGridItem
                iconImage={<img src={IconSet['servbellIcon0407']} alt="" />}
                title="Concierge service"
                detail="Our in-house team of science experts supports teachers at every step, from setting up Stile to assisting with implementation and beyond."
              />
            </WITBGridRow>
          </ScrollIndicatorContainer>
        </WITBFeatureSection>
      </div>

      <div className="content">
        {/* 05 - prof. learning */}
        <WITBFeatureSection
          id="professional-learning"
          title="Professional learning"
          learnMoreHref="/what-is-stile/stile-pl/"
        >
          <ScrollIndicatorContainer>
            <WITBGridRow>
              <WITBGridItem
                iconImage={<img src={IconSet['classroomIcon0501']} alt="" />}
                title="In-person or virtual PL"
                detail="Evidence-based science teaching at your convenience."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['reportcardIcon0502']} alt="" />}
                title="Self-paced learning materials"
                detail="Access everything Stile offers on your schedule."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['vidconIcon0503']} alt="" />}
                title="Flash PL"
                detail="Quick, 20-minute monthly webinars to keep you inspired."
              />
            </WITBGridRow>
            <WITBGridRow>
              <WITBGridItem
                iconImage={<img src={IconSet['meetingIcon0504']} alt="" />}
                title="PD and the pub"
                detail="Connect with peers and explore what works in real classrooms."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['conflictIcon0505']} alt="" />}
                title="Super Teacher Challenge"
                detail="An interactive way to enhance your teaching skills."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['win10Icon0506']} alt="" />}
                title="Tailored PL"
                detail="Customised to fit your pedagogical model."
              />
            </WITBGridRow>
          </ScrollIndicatorContainer>
        </WITBFeatureSection>
      </div>

      <div className="content">
        {/* 06 - platform features */}
        <WITBFeatureSection
          id="powerful-platform"
          title="Powerful and secure platform"
          learnMoreHref="/what-is-stile/technology-for-teaching/"
        >
          <ScrollIndicatorContainer>
            <WITBGridRow>
              <WITBGridItem
                iconImage={<img src={IconSet['toolsIcon0602']} alt="" />}
                title="Lesson builder"
                detail="Access our lesson builder to modify any Stile lesson or create your own from scratch."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['clouduploadIcon0603']} alt="" />}
                title="Unlimited data storage"
                detail="Enjoy unlimited, secure storage for all your lesson plans, student data, and resources."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['padlockIcon0601']} alt="" />}
                title="Secure and private"
                detail="Routinely audited by third parties to the highest standards, ensuring your data is always protected."
              />
            </WITBGridRow>
            <WITBGridRow>
              <WITBGridItem
                iconImage={<img src={IconSet['puzzleIcon0604']} alt="" />}
                title="Seamless integrations"
                detail="Integrates effortlessly with Canvas, and other popular platforms, making it easy to sync your tools."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['combochartIcon0605']} alt="" />}
                title="Real-time data and insights"
                detail="Gain valuable insights with real-time data tracking, helping you monitor student progress instantly."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['favouriteIcon0606']} alt="" />}
                title="Unlimited support"
                detail="Get unlimited phone and email support from our team of experts, whenever you need it."
              />
            </WITBGridRow>
          </ScrollIndicatorContainer>
        </WITBFeatureSection>
      </div>
      <div className="content">
        {/* 07 - services and support */}
        <WITBFeatureSection
          id="responsive-support"
          title="Responsive support"
          learnMoreHref="/what-is-stile/responsive-support/"
        >
          <WITBGridContainer>
            <WITBGridRow>
              <WITBGridItem
                iconImage={<img src={IconSet['teamworkIcon0701']} alt="" />}
                title="Experienced teachers on call"
                detail="Our team of in-house teachers is available every school day to answer calls and emails. Co-plan lessons, work through misconceptions, or ask for pedagogical guidance—we’re here."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['workspaceIcon0702']} alt="" />}
                title="Software engineers on call"
                detail="Speak directly to the software developers who write code for Stile every day, and collaborate with them to develop custom reporting and integrations."
              />
              <WITBGridItem
                iconImage={<img src={IconSet['femwriteIcon0703']} alt="" />}
                title="Customisation support"
                detail="While Stile’s intuitive editing tools make it easy to edit lessons, we’re here to help adapt our lessons to your local contexts and phenomena."
              />
            </WITBGridRow>
          </WITBGridContainer>
        </WITBFeatureSection>
      </div>
      <BottomCTA
        heading="So, what does a Stile classroom look like?"
        copy={
          <div>
            <p>
              Instead of telling you, we’d rather show you. Step inside and discover what a Stile
              classroom really looks and feels like—and, most importantly, how it lights a spark in
              both teachers and students.
            </p>
            <p>Ready to dive in?</p>
          </div>
        }
        image={
          <StaticImage
            src="../../../static/stile-classroom.jpg"
            alt="Stile classroom"
            quality={100}
            style={{ aspectRatio: '1/1' }}
          />
        }
        button={
          <Button href="/what-is-stile/stile-classroom/" label="See inside a Stile classroom" />
        }
      />
    </WhatsIncludedPageLayout>
  );
}

export default WhatsIncludedPage;

// Styled Components specific to the page
