import * as React from 'react';

import './Logo.css';

type LogoProps = React.HTMLAttributes<HTMLDivElement> & {
  children?: never;
};

export const Logo = ({ ...props }: React.PropsWithChildren<LogoProps>) => {
  return (
    <svg width="68" height="26" viewBox="0 0 68 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9533_47184)">
        <path
          d="M13.4658 11.154L8.617 9.84523C6.38654 9.2592 5.78529 8.30203 5.78529 7.14951C5.78529 5.2547 7.62784 4.19985 10.0135 4.19985C12.147 4.19985 13.8343 5.13749 15.5023 6.38768C16.2394 6.95417 16.8406 6.89557 17.3837 6.34861L18.8771 4.82494C19.265 4.43426 19.5948 3.86777 18.7414 3.04733C17.0734 1.4846 14.0865 0 10.1686 0C4.66036 0 0.684318 3.0864 0.664923 7.46206C0.664923 10.9196 2.8178 13.2442 6.67747 14.26L11.1772 15.4515C13.621 16.1352 14.9011 16.9947 14.8817 18.7138C14.8623 20.4328 13.3883 21.7415 10.4014 21.7415C7.41449 21.7415 5.51375 20.3742 4.09789 18.9677C3.57422 18.4403 3.10873 18.1863 2.4299 18.7138L0.432179 20.2374C-0.246657 20.7648 -0.11089 21.6243 0.47097 22.2104C2.10018 24.027 5.20343 25.9805 10.1104 25.9805C16.8406 26 20.0408 22.0541 20.0602 18.2645C20.0602 14.299 17.4613 12.1893 13.4464 11.1345L13.4658 11.154Z"
          fill="#00E65A"
        />
        <path
          d="M59.1351 7.46204C53.8013 7.46204 49.9805 11.6033 49.9805 16.7994C49.9805 21.9955 53.7626 25.9804 59.2126 25.9804C64.6627 25.9804 65.3028 24.4568 66.7574 22.601C67.0096 22.308 67.0096 21.8392 66.6217 21.5462L64.3524 19.9444C64.2554 19.8858 64.1391 19.8467 64.0033 19.8467C63.8093 19.8467 63.6542 19.9248 63.5184 20.0421C63.4796 20.0811 63.4602 20.1007 63.4214 20.1397C62.3741 21.3313 61.191 21.9955 59.3484 21.9955C57.5059 21.9955 55.062 20.3546 54.8099 17.8933H67.2229C67.9017 17.8933 68.0569 17.5221 68.0569 17.0924V16.2134C68.0569 11.5642 64.5658 7.46204 59.1932 7.46204H59.1351ZM55.1202 13.9279C55.8185 12.3847 57.2925 11.4275 59.0963 11.4275C60.9 11.4275 62.3935 12.2674 63.0723 13.9279H55.1202Z"
          fill="#00E65A"
        />
        <path
          d="M32.5414 23.6754L31.3389 21.7415C31.1256 21.4094 30.6989 21.2922 30.3498 21.468C29.8843 21.722 29.2442 21.9954 28.6042 21.9954C27.2465 21.9954 26.7228 20.9015 26.7228 19.8076C26.7228 18.7137 26.7228 11.4275 26.7228 11.4275H31.8238C32.2311 11.4275 32.5802 11.0563 32.5802 10.6656V8.24338C32.5802 7.81363 32.2117 7.48155 31.8238 7.48155H26.7228V3.06683C26.7228 2.65661 26.3543 2.30499 25.9664 2.30499H22.8244C22.3977 2.30499 22.068 2.67614 22.068 3.06683V7.46202H19.6629C19.2556 7.46202 18.9453 7.7941 18.9453 8.22385V10.6461C18.9453 11.0563 19.275 11.4079 19.6629 11.4079H22.068C22.068 15.3538 22.068 19.3193 22.068 20.3936C22.068 23.48 24.0463 25.7655 27.4987 25.9414C29.8067 26.0586 31.4553 25.2967 32.3475 24.6716C32.6772 24.4372 32.7742 23.9879 32.5608 23.6363L32.5414 23.6754Z"
          fill="#00E65A"
        />
        <path
          d="M45.6627 19.8077V0.761833C45.6627 0.351615 45.2942 0 44.9063 0H41.7642C41.3375 0 41.0078 0.371149 41.0078 0.761833V20.4328C41.0078 23.5192 42.9861 25.8047 46.4385 25.9805C48.6302 26.0977 50.1624 25.414 50.9964 24.8865C51.3649 24.6717 51.4619 24.1833 51.2485 23.8122L50.046 21.8783C49.8327 21.5462 49.4254 21.429 49.0763 21.5853C48.7272 21.7415 48.1259 21.976 47.5828 21.976C46.2252 21.976 45.7015 20.882 45.7015 19.7881"
          fill="#00E65A"
        />
        <path
          d="M39.0064 12.6386V8.22387C39.0064 7.81365 38.6379 7.46204 38.25 7.46204H35.108C34.6813 7.46204 34.3516 7.83319 34.3516 8.22387V25.2186C34.3516 25.6288 34.7201 25.9804 35.108 25.9804H38.25C38.6767 25.9804 39.0064 25.6093 39.0064 25.2186V12.6386Z"
          fill="#00E65A"
        />
        <path
          d="M36.6729 0C35.2183 0 34.0352 1.21112 34.0352 2.65665C34.0352 4.10218 35.1989 5.3133 36.6729 5.3133C38.147 5.3133 39.2719 4.10218 39.2719 2.65665C39.2719 1.21112 38.1082 0 36.6729 0Z"
          fill="#00E65A"
        />
      </g>
      <defs>
        <clipPath id="clip0_9533_47184">
          <rect width="68" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
