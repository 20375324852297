import React, { useEffect, useRef, useState } from 'react';

import ReactPlayer from 'react-player';

interface VideoPlayerProps {
  url: string;
  playing?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  playing = true,
  loop = true,
  muted = true,
  controls = false,
  width = '100%',
  height = '100%',
  borderRadius = '32px',
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(playing);
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const videoRef = useRef<ReactPlayer | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!loop) {
      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsPlaying(true);
          } else {
            setIsPlaying(false);
            if (videoRef.current) {
              videoRef.current.seekTo(0);
            }
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.9,
      });

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }
  }, [loop]);

  return (
    <div
      className="video"
      ref={containerRef}
      style={{
        borderRadius,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {/* Skeleton overlay until video is loaded */}
      {!videoLoaded && (
        <div
          className="skeleton skeleton-video"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 2,
            // Optionally enforce aspect ratio if needed
            // aspectRatio: '16/9',
          }}
        />
      )}
      <ReactPlayer
        ref={videoRef}
        url={url}
        playing={isPlaying}
        loop={loop}
        muted={muted}
        controls={controls}
        width={width}
        height={height}
        playsinline
        onReady={() => setVideoLoaded(true)}
        onEnded={() => {
          if (!loop) {
            setIsPlaying(false);
          }
        }}
        style={{
          opacity: videoLoaded ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      />
    </div>
  );
};
