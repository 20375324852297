import * as React from 'react';

import type { PageProps } from 'gatsby';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import { Link } from 'stile-shared/src/components/2024/common/Link';
import { Logo } from 'stile-shared/src/components/2024/common/Logo';
import { Grid } from 'stile-shared/src/components/2024/layout/Grid';

import { PageLayout } from 'templates/2024/PageLayout';

export function Head() {
  return <title>2024 Test Page | Stile Education</title>;
}

function TwentyTwentyFourPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <h1>2024 Test Page</h1>
      <Grid>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
        <div>6</div>
        <div>7</div>
        <div>8</div>
        <div>9</div>
        <div>10</div>
        <div>11</div>
        <div>12</div>
      </Grid>

      <hr />

      <Logo />

      <hr />

      <div>
        <Button style="primary" label="Stile for schools" />{' '}
      </div>
      <div style={{ paddingTop: 20 }}>
        <Button style="secondary" label="Stile for schools" />
      </div>

      <hr />

      <h1>Typography</h1>
      <h1>Heading 1</h1>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <h4>Heading 4</h4>
      <h5>Heading 5</h5>
      <h6>Heading 6</h6>
      <h6 className="h7">Heading 7</h6>
      <h6 className="h8">Heading 8</h6>
      <h6 className="h9">Heading 9</h6>

      <hr />

      <div>
        Body text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque malesuada nulla
        blandit tellus varius ullamcorper. Praesent congue urna sapien, at bibendum mauris mattis
        vel. Duis at turpis eu eros venenatis commodo. Etiam faucibus magna at sodales aliquam.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a pretium nisl. Proin
        faucibus metus odio, nec viverra ligula imperdiet a.
      </div>

      <div className="--small" style={{ paddingTop: 20 }}>
        Small body text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque malesuada
        nulla blandit tellus varius ullamcorper. Praesent congue urna sapien, at bibendum mauris
        mattis vel. Duis at turpis eu eros venenatis commodo. Etiam faucibus magna at sodales
        aliquam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a pretium nisl.
        Proin faucibus metus odio, nec viverra ligula imperdiet a.
      </div>

      <hr />

      <Link url="/2024">Text Link</Link>
      <Link url="/2024" className="--small">
        Small Text Link
      </Link>

      <hr />

      <div className="feature">Feature</div>
      <div className="quote">Quote</div>
      <div className="quote --small">Small Quote</div>
    </PageLayout>
  );
}

export default TwentyTwentyFourPage;
