import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import alan from 'stile-shared/assets/images/homepage/alan.jpg';
import alex from 'stile-shared/assets/images/homepage/alex.jpg';
import andy from 'stile-shared/assets/images/homepage/andy.jpg';
import filming from 'stile-shared/assets/images/homepage/byron_filming.jpg';
import workbooks from 'stile-shared/assets/images/homepage/byron_workbooks.jpg';
import cham from 'stile-shared/assets/images/homepage/cham.jpg';
import cj from 'stile-shared/assets/images/homepage/cj.jpg';
import design from 'stile-shared/assets/images/homepage/design_team.jpg';
import developers from 'stile-shared/assets/images/homepage/developers.jpg';
import execs from 'stile-shared/assets/images/homepage/execs.jpg';
import jacky from 'stile-shared/assets/images/homepage/jacky.jpg';
import kat from 'stile-shared/assets/images/homepage/kat.jpg';
import mark from 'stile-shared/assets/images/homepage/mark.jpg';
import mel from 'stile-shared/assets/images/homepage/mel.jpg';
import podcast from 'stile-shared/assets/images/homepage/podcast.jpg';
import sandpit from 'stile-shared/assets/images/homepage/sandpit.jpg';
import school from 'stile-shared/assets/images/homepage/school_filming.jpg';
import sean from 'stile-shared/assets/images/homepage/sean.jpg';
import stickyNotes from 'stile-shared/assets/images/homepage/sticky_notes.jpg';
import tShirt from 'stile-shared/assets/images/homepage/t-shirt.jpg';
import tim from 'stile-shared/assets/images/homepage/tim.jpg';

import { Button } from '../../common/Button';

const ScrollingSection = () => {
  // 16 images for left/right columns
  const images = [
    podcast,
    sean,
    stickyNotes,
    execs,
    tShirt,
    sandpit,
    workbooks,
    cj,
    kat,
    tim,
    mel,
    alan,
    jacky,
    alex,
    filming,
    cham,
    school,
    mark,
  ];
  const leftImages = images.slice(0, 9);
  const rightImages = images.slice(9, 18);

  // Middle-column text items
  const textItems = [
    <h2 key="1" style={{ margin: '0' }}>
      For every lesson we spend
    </h2>,
    <h1 key="2" className="--jumbo" style={{ color: 'var(--stile-green-3)' }}>
      50 hours
    </h1>,
    <h2 key="3">finding modern phenomena,</h2>,
    <h2 key="4">scaffolding coherent lesson sequences,</h2>,
    <h2 key="5">crafting challenging questions,</h2>,
    <h2 key="6">creating custom simulations,</h2>,
    <h2 key="7">writing teacher supports,</h2>,
    <h2 key="8" style={{ margin: '0' }}>
      and testing in classrooms.
    </h2>,
  ];

  // Active text index (flipped via Intersection Observer)
  const [activeIndex, setActiveIndex] = useState(0);

  // Refs for key elements we need to measure
  const designRef = useRef<HTMLImageElement | null>(null);

  // Height for each text trigger (we calculate this dynamically)
  const [textItemHeight, setTextItemHeight] = useState(0);

  // Refs for invisible trigger divs
  const triggerRefs = useRef<(HTMLDivElement | null)[]>([]);

  // Intersection Observer for flipping text
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-50% 0px -50% 0px', // triggers when a trigger's center crosses the viewport center
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveIndex(Number((entry.target as HTMLElement).dataset.index));
        }
      });
    }, options);

    triggerRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      triggerRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  /**
   * Measure available space based on 7 * design image height,
   * divided equally among all text items and the button.
   */
  const measurePositions = () => {
    if (!designRef.current || textItems.length === 0) {
      return;
    }

    const designRect = designRef.current.getBoundingClientRect();
    const designHeight = designRect.height;

    // Total space is 7 times the height of the design image
    const totalSpace = 8 * designHeight;
    // Divide equally among text items and the button (textItems.length + 1 segments)
    const perItemHeight = (totalSpace - 450) / (textItems.length - 3);
    // Cap each segment's height if necessary (using 280 as maximum)
    const cappedHeight = Math.max(perItemHeight, 240);
    setTextItemHeight(Math.max(0, cappedHeight));
  };

  // Measure positions after images load and on window resize
  useLayoutEffect(() => {
    measurePositions();
    window.addEventListener('resize', measurePositions);
    return () => {
      window.removeEventListener('resize', measurePositions);
    };
  }, [textItems.length]);

  // Render left column images
  const renderLeftImages = leftImages.map((img, i) => (
    <img
      key={i}
      src={img}
      alt={`Left ${i + 1}`}
      onLoad={measurePositions}
      style={{
        width: '100%',
        height: 'auto',
        display: 'block',
        borderRadius: '16px',
      }}
    />
  ));

  // Render right column images
  const renderRightImages = rightImages.map((img, i) => (
    <img
      key={i}
      src={img}
      alt={`Right ${i + 1}`}
      onLoad={measurePositions}
      style={{
        width: '100%',
        height: 'auto',
        display: 'block',
        borderRadius: '16px',
      }}
    />
  ));

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '320fr 470fr 320fr',
        maxWidth: '1160px',
        gap: '18px',
        alignItems: 'stretch', // Ensure all grid items have equal height
      }}
    >
      {/* LEFT COLUMN */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '18px', paddingTop: '208px' }}>
        {renderLeftImages}
      </div>

      {/* MIDDLE COLUMN */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '18px',
          alignItems: 'center',
          height: '100%', // Stretch to fill the grid cell
        }}
      >
        {/* 1) Top image */}
        <img
          src={andy}
          alt="andy"
          style={{ width: '100%', height: 'auto', display: 'block', borderRadius: '16px' }}
        />

        {/* 2) Design image used for measurement */}
        <img
          ref={designRef}
          src={design}
          alt="design"
          onLoad={measurePositions}
          style={{
            width: '100%',
            height: 'auto',
            display: 'block',
            borderRadius: '16px',
            marginBottom: '150px',
          }}
        />

        {/* 3) Combined Sticky Text & Button Container */}
        <div style={{ position: 'relative', height: textItemHeight * (textItems.length - 2) + 50 }}>
          <div
            style={{
              position: 'sticky',
              top: '50%',
              transform: 'translateY(-50%)',
              textAlign: 'center',
              zIndex: 10,
              // padding: '50px 0',
              maxWidth: '412px',
            }}
          >
            {textItems[activeIndex]}
          </div>
          {/* Invisible triggers for text items */}
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            {textItems.map((_, i) => (
              <div
                key={i}
                data-index={i}
                ref={(el) => (triggerRefs.current[i] = el)}
                style={
                  i == 1
                    ? { height: '250px' }
                    : i != 0 && i != textItems.length - 1
                    ? {
                        height: `${textItemHeight}px`,
                      }
                    : { height: '100px' }
                }
              />
            ))}
          </div>
          {/* Button placed at the bottom */}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            // transform: 'translateY(-150px)',
          }}
        >
          <Button label="Our guarantees & principles" href="/who-we-are/our-principles/" />
        </div>

        {/* 5) Final bottom image */}
        <img
          src={developers}
          alt="developers"
          style={{
            width: '100%',
            height: 'auto',
            // transform: 'translateY(-150px)',
            borderRadius: '16px',
            marginTop: '80px',
          }}
        />
      </div>

      {/* RIGHT COLUMN */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '18px', paddingTop: '208px' }}>
        {renderRightImages}
      </div>
    </div>
  );
};

export default ScrollingSection;
