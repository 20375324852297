import * as React from 'react';

import type { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from 'gatsby';

import {
  CLARITY_ID,
  FACEBOOK_PIXEL_ID,
  GOOGLE_ADS_ID,
  GOOGLE_UA_ID,
  LINKEDIN_INSIGHT_PARTNER_ID,
} from '../constants';

import { GlobalStyles as GlobalStyle_2020 } from '../styles/2020/global';
import { GlobalStyles as GlobalStyle_2024 } from '../styles/2024';

import { ClarityScript } from './ClarityScript';
import { FacebookPixel } from './FacebookPixel';
import { GoogleGtag } from './GoogleGtag';
import { LinkedInInsight } from './LinkedInInsight';
import { matchesNewStylePath } from './paths';

// NOTE: It might seem to make sense to move <GlobalStyles /> to WrapRootElement instead
// but then gatsby-plugin-styled-components will not generate styles during build
// and you will get a flash of unstyled content on page load.
export function WrapPageElement({
  element,
  props,
}: WrapPageElementBrowserArgs | WrapPageElementNodeArgs) {
  const useNewStyles = matchesNewStylePath(props.path);
  return (
    <>
      {!useNewStyles && <GlobalStyle_2020 />}
      {useNewStyles && <GlobalStyle_2024 />}
      {element}
      <GoogleGtag trackingIds={[GOOGLE_UA_ID, GOOGLE_ADS_ID]} />
      <LinkedInInsight partnerId={LINKEDIN_INSIGHT_PARTNER_ID} />
      <FacebookPixel pixelId={FACEBOOK_PIXEL_ID} />
      <ClarityScript id={CLARITY_ID} />
    </>
  );
}
