import React, { useEffect, useRef, useState } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import aiFeedback from 'stile-shared/assets/videos/ai_feedback.mp4';
import balanceEquation from 'stile-shared/assets/videos/balance_equations.mp4';
import editQuestion from 'stile-shared/assets/videos/edit_question.mp4';
import lessonVideo from 'stile-shared/assets/videos/lesson_video.mp4';
import maths from 'stile-shared/assets/videos/maths.mp4';
import studentAnswerFeedback from 'stile-shared/assets/videos/student_answer_feedback.mp4';
import writtenResponse from 'stile-shared/assets/videos/written_response.mp4';
import styled from 'styled-components';

import { StyledWITBTopSection } from '../common/Included/WITBTopSection/WITBTopSection.styles';
import { Button } from 'stile-shared/src/components/2024/common/Button';
import VideoGallery from 'stile-shared/src/components/2024/layout/VideoGallery';

gsap.registerPlugin(ScrollTrigger);

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return isMobile;
}

function useHeadingOffset(ref: React.RefObject<HTMLDivElement>, minOffset = 20, maxOffset = 460) {
  const [offset, setOffset] = useState(0);
  const isMobile = useIsMobile(); // use the custom hook

  useEffect(() => {
    function handleScroll() {
      if (!ref.current || isMobile) return;
      const rect = ref.current.getBoundingClientRect();
      const scrolledIntoSection = -rect.top;
      const newOffset = Math.min(Math.max(scrolledIntoSection, minOffset), maxOffset);
      setOffset(newOffset);
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [ref, minOffset, maxOffset, isMobile]);

  return !isMobile ? offset : 0;
}

const ScrollJackedSection = styled.div`
  position: relative;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  p {
    font-size: 21px;
    font-weight: 500;
    line-height: 26px;
    max-width: 768px;
    @media (max-width: 768px) {
      font-size: 19px;
      line-height: 24px;
    }
  }
  a {
    margin-bottom: 80px;
  }
`;
const Header = styled<typeof StyledWITBTopSection>(StyledWITBTopSection)`
  z-index: 1;
  will-change: transform;
  transition: transform 0.2s ease-out;
  position: relative;
  max-width: 1160px;
  padding-top: 150px;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    transform: none !important;
    position: static;
    padding: 60px 25px 0;
    margin-bottom: 0;
  }
`;

// Create a wrapper div for the video gallery to apply the shadow styling
const GalleryWrapper = styled.div`
  position: sticky !important;
  top: 0 !important;
  z-index: 99 !important;
  width: 100% !important;
  overflow: visible !important;
  display: flex !important;
  justify-content: center !important; /* Center the images block horizontally */

  /* Add white shadow around the image block - more subtle, less tall */
  @media (min-width: 768px) {
    &::before {
      content: '';
      position: absolute;
      top: -100px; /* Reduced from -100px */
      left: 0;
      right: 0;
      height: 400px; /* Reduced from 100px */
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 42%);
      z-index: 9;
      pointer-events: none;
    }
  }
  /* No longer needed with the more subtle shadow */
  &::after {
    display: none;
  }
`;

// Keep the original ImagesBlock but make sure position is applied
const ImagesBlock = styled<typeof VideoGallery>(VideoGallery)`
  position: relative !important;
  background: rgba(255, 255, 255, 1) !important;
  z-index: 999 !important;
  width: calc(100% * 1.225) !important; /* Match the width from VideoGalleryContainer */
  max-width: 1440px !important; /* Match the max-width from VideoGalleryContainer */
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
  gap: 24px !important;
  overflow: hidden !important;
`;

export default function TechnologySection() {
  const sectionRef = useRef(null);
  const headingOffset = useHeadingOffset(sectionRef);
  const isMobile = useIsMobile();

  return (
    <ScrollJackedSection ref={sectionRef}>
      <ContentWrapper>
        <Header
          style={{
            transform: `translateY(${headingOffset}px)`,
            // When not scrolled, z-index is high so the button is clickable.
            // Once scrolled, z-index is lowered so the header goes behind the videos.
          }}
        >
          <h1 className="--huge">Powerful, purposeful technology</h1>
          <p>
            We hate technology for technology&apos;s sake, but used well we believe it can give
            teachers superpowers. Our teaching and learning platform has been refined and
            battle-tested for over a decade in thousands of classrooms, and is continually being
            improved with formidable new AI-powered features.
            <br />
            <br />
            It&apos;s secure, private and powerful.
            <br />
            <br />
          </p>
          {isMobile && (
            <Button
              label="Learn more about our platform"
              newTab={false}
              href="/what-is-stile/technology-for-teaching/"
            />
          )}
        </Header>
        {isMobile ? (
          <ImagesBlock
            items={[
              { type: 'video', url: editQuestion },
              { type: 'video', url: maths },
              { type: 'video', url: writtenResponse },
              { type: 'video', url: aiFeedback },
              { type: 'video', url: studentAnswerFeedback },
              { type: 'video', url: balanceEquation },
              { type: 'video', url: lessonVideo },
            ]}
          />
        ) : (
          <GalleryWrapper>
            <ImagesBlock
              items={[
                { type: 'video', url: editQuestion },
                { type: 'video', url: maths },
                { type: 'video', url: writtenResponse },
                { type: 'video', url: aiFeedback },
                { type: 'video', url: studentAnswerFeedback },
                { type: 'video', url: balanceEquation },
                { type: 'video', url: lessonVideo },
              ]}
            />
          </GalleryWrapper>
        )}
        {!isMobile && (
          <Button
            label="Learn more about our platform"
            newTab={false}
            href="/what-is-stile/technology-for-teaching/"
          />
        )}
      </ContentWrapper>
    </ScrollJackedSection>
  );
}
