// src/components/blocks/our-principles/FocusCards.tsx
import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import GuaranteeBadge from './GuaranteeBadge';

export const FocusCardsSectionWrapper = styled.div`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 150px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    margin-bottom: 0;
    padding: 0;
  }
`;

export const FocusCardsContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    gap: 40px;
    height: auto;
  }
`;

export const FocusCardsHeading = styled.div`
  width: 764px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    gap: 0;
  }
`;

export const FocusCardsTitle = styled.h3`
  color: #00a541;
  font-size: 55px;
  font-family: 'Boing', sans-serif;
  font-weight: 500;
  line-height: 60px;
  word-wrap: break-word;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 40px;
    margin: 60px 0 0 0;
  }
`;

export const FocusCardsSubtitle = styled.p`
  width: 566px;
  color: #2b2b2b;
  font-size: 30px;
  font-family: 'Graphik', sans-serif;
  font-weight: 600;
  line-height: 38px;
  word-wrap: break-word;
  margin: 0;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 24px;
    margin: 20px 0 0 0;
  }
`;

export const FocusRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 28px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
`;

export const FocusCardWrapper = styled.div`
  background: var(--stile-grey-1);
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.07), 0px 19px 19px 0px rgba(0, 0, 0, 0.058),
    0px 43px 26px 0px rgba(0, 0, 0, 0.039);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 330px;
  height: 583px;

  @media (max-width: 768px) {
    width: 340px;
    padding-bottom: 30px;
    height: auto;
    gap: 25px;
  }
`;

export const FocusContent = styled.div`
  padding: 35px 19px 20px 19px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  @media (max-width: 768px) {
    padding: 30px 19px 0px 19px;
  }
`;

export const FocusHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
`;

export const FocusGuarantee = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
`;

export const FocusBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
`;

export const FocusBadgeIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: relative;

  svg {
    width: 20px;
    height: 20px;
    fill: #00a541;
  }
`;

export const FocusBadgeText = styled.div`
  text-align: center;
  color: #00a541;
  font-size: 16px;
  font-family: 'Graphik', sans-serif;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
`;

export const FocusTitle = styled.h4`
  width: 330px;
  text-align: center;
  margin: 10px 0 0 0;
  color: #2b2b2b;
  font-size: 40px;
  font-family: 'Boing', sans-serif;
  font-weight: 500;
  line-height: 45px;

  @media (max-width: 768px) {
    width: 100%;
    font-family: Boing;
    font-size: 26px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    white-space: nowrap;
  }

  @media (min-width: 769px) {
    word-spacing: 50vw;
  }
`;

export const FocusImageWrapper = styled.div`
  width: 260px;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const FocusFooter = styled.div`
  height: 72px;
  display: flex;
  width: 313px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 21px;
`;

export const FocusText = styled.div`
  text-align: center;
  color: #2b2b2b;
  font-size: 16px;
  font-family: 'Graphik', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
  margin: 0 0 21px 0;
`;

const FocusCards: React.FC = () => (
  <FocusCardsSectionWrapper>
    <FocusCardsContainer>
      <FocusCardsHeading>
        <FocusCardsTitle>Our guarantees</FocusCardsTitle>
        <FocusCardsSubtitle>
          Some people talk the talk. <br />
          We walk the walk.
        </FocusCardsSubtitle>
      </FocusCardsHeading>
      <FocusRow>
        {/* First Focus Card */}
        <FocusCardWrapper>
          <FocusContent>
            <FocusHeader>
              <FocusGuarantee>
                <FocusBadge>
                  <FocusBadgeIconWrapper>
                    <GuaranteeBadge />
                  </FocusBadgeIconWrapper>
                  <FocusBadgeText>Guarantee</FocusBadgeText>
                </FocusBadge>
                <FocusTitle>Always Evolving</FocusTitle>
              </FocusGuarantee>
            </FocusHeader>
            <FocusImageWrapper>
              <StaticImage
                src="./images/02-01-always-evolving.png"
                alt="Always Evolving"
                placeholder="blurred"
                layout="constrained"
                width={260}
                height={260}
              />
            </FocusImageWrapper>
          </FocusContent>
          <FocusFooter>
            <FocusText>
              We’ll send you a <strong>$500 voucher</strong> <br />
              for lab equipment if you find a <br />
              <strong>dataset over 5 years old</strong>.
            </FocusText>
          </FocusFooter>
        </FocusCardWrapper>

        {/* Repeat for other focus cards with hardcoded StaticImage src */}
        {/* Second Focus Card */}
        <FocusCardWrapper>
          <FocusContent>
            <FocusHeader>
              <FocusGuarantee>
                <FocusBadge>
                  <FocusBadgeIconWrapper>
                    <GuaranteeBadge />
                  </FocusBadgeIconWrapper>
                  <FocusBadgeText>Guarantee</FocusBadgeText>
                </FocusBadge>
                <FocusTitle>Just Works</FocusTitle>
              </FocusGuarantee>
            </FocusHeader>
            <FocusImageWrapper>
              <StaticImage
                src="./images/02-02-just-works.png"
                alt="Just Works"
                placeholder="blurred"
                layout="constrained"
                width={260}
                height={260}
              />
            </FocusImageWrapper>
          </FocusContent>
          <FocusFooter>
            <FocusText>
              We’ll send you a <strong>$500 voucher</strong>
              <br />
              for lab equipment if one of Stile’s hands-on activities
              <strong> doesn’t work as promised</strong>.
            </FocusText>
          </FocusFooter>
        </FocusCardWrapper>

        {/* Third Focus Card */}
        <FocusCardWrapper>
          <FocusContent>
            <FocusHeader>
              <FocusGuarantee>
                <FocusBadge>
                  <FocusBadgeIconWrapper>
                    <GuaranteeBadge />
                  </FocusBadgeIconWrapper>
                  <FocusBadgeText>Guarantee</FocusBadgeText>
                </FocusBadge>
                <FocusTitle>Scientific Accuracy</FocusTitle>
              </FocusGuarantee>
            </FocusHeader>
            <FocusImageWrapper>
              <StaticImage
                src="./images/02-03-scientific-accuracy.png"
                alt="Scientific Accuracy"
                placeholder="blurred"
                layout="constrained"
                width={260}
                height={260}
              />
            </FocusImageWrapper>
          </FocusContent>
          <FocusFooter>
            <FocusText>
              We’ll send you a <strong>$500 voucher</strong> <br />
              for lab equipment if you spot a <br />
              <strong>scientific error</strong> in our lessons.
            </FocusText>
          </FocusFooter>
        </FocusCardWrapper>
      </FocusRow>
    </FocusCardsContainer>
  </FocusCardsSectionWrapper>
);

export default FocusCards;
